function createMarkup(htmlContent) {
    return {__html: htmlContent}
}

function encodeForm(data) {
    return Object.keys(data)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
        .join('&');
}

function wrapIframe() {
    const iframes = document.querySelectorAll('iframe');

    Array.from(iframes).map((iframe) => {
        const width = iframe.width || parseInt(iframe.style.width);
        const height = iframe.height || parseInt(iframe.style.height);

        if (width || height) {
            const ratio = Math.round(height / (width / 100) * 100) / 100;
            const wrapper = document.createElement('div');

            wrapper.classList.add('embed-responsive');

            wrapper.style = `
                padding-bottom: ${ratio}%;
            `;

            iframe.parentNode.appendChild(wrapper)
            wrapper.appendChild(iframe)
        }

        return null;
    });
};

function setBackgroundStyle(image, isEmbed = true) {
  return image
    ? {
      backgroundImage: prefersReducedData() ? 'none' : `
        ${isEmbed ? 'var(--spinner-url),' : ''}
        url(${image.node.fluid.src}),
        url(${image.node.fluid.base64})
      `,
      backgroundSize: `
        ${isEmbed ? '50px 50px,' : ''}
        cover,
        cover
      `,
      backgroundBlendMode: `
        ${isEmbed ? 'luminosity,' : ''}
        normal,
        normal
      `,
    } : {}
}

function getConnection() {
  return navigator.connection
    || navigator.mozConnection
    || navigator.webkitConnection
    || {};
}

function prefersReducedData() {
  const connection = getConnection();

  return connection?.saveData || ['slow-2g', '2g', '3g'].includes(connection?.effectiveType);
}

function getExternalSiteName(url) {

  switch (new URL(url).host) {
    case 'bandcamp.com':
    case 'www.bandcamp.com':
      return 'Bandcamp';

    case 'soundcloud.com':
    case 'w.soundcloud.com':
    case 'www.soundcloud.com':
      return 'SoundCloud';

    case 'mixcloud.com':
    case 'www.mixcloud.com':
      return 'Mixcloud';

    case 'www.youtu.be':
    case 'www.youtube-nocookie.com':
    case 'www.youtube.com':
    case 'youtu.be':
    case 'youtube-nocookie.com':
    case 'youtube.com':
      return 'YouTube';

    default:
      throw Error('Unsupported Service');
  };
}

function getPrivacyPolicy(url) {

  switch(getExternalSiteName(url).toLowerCase()) {
    case 'bandcamp':
      return 'https://bandcamp.com/privacy';

    case 'mixcloud':
      return 'https://www.mixcloud.com/privacy';

    case 'soundcloud':
      return 'https://soundcloud.com/pages/privacy';

    case 'youtube':
      return 'https://policies.google.com/privacy';

    default:
      throw Error('Unsupported Service');
  }
}

function isArray(inputData) {
  return Object.prototype.toString.call(inputData) === '[object Array]';
}

export {
    createMarkup,
    encodeForm,
    getExternalSiteName,
    getPrivacyPolicy,
    isArray,
    setBackgroundStyle,
    wrapIframe
}
