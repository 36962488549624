import React, { useEffect} from 'react'

import { Content } from '@components/Content'
import { Seo } from '@components/Seo'

import { decodeHTML } from 'entities';
import { createMarkup } from '@lib/util';
import { responsiveRatio } from '@nutriot/responsive-ratio';

import './wp.scss';

const WordpressTemplate = props => {
  const { pageContext } = props;
  const { content, dateFormat, tags, title } = pageContext;

  useEffect(() => {
    responsiveRatio();

    return () => undefined;
  }, []);

  return (
    <>
      <Seo title={decodeHTML(title)} keywords={tags} />
      <Content fullwidth={true} textblock={true}>
        <section className="item post">
          <h1 dangerouslySetInnerHTML={createMarkup(title)}></h1>
          <div dangerouslySetInnerHTML={createMarkup(content)}></div>
          <div className="meta">
            <p>{`Published on ${dateFormat.month} ${dateFormat.day}, ${dateFormat.year}`}</p>
          </div>
        </section>
      </Content>
    </>
  )
}

export default WordpressTemplate
